<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" class="icon-chat">
        <rect class="cls-2" width="22" height="22" style="fill : none;"/>
        <path class="cls-1" d="m5.27,20.28c-.14,0-.28-.03-.41-.1-.36-.21-.44-.58-.41-.83.01-.09.04-.25.84-3.02-2.69-1.5-4.29-3.93-4.29-6.58C1,5.32,5.49,1.72,11,1.72s10,3.6,10,8.01-4.49,8.01-10,8.01c-.5-.02-1.01-.03-1.51-.09-3.31,2.31-3.78,2.54-3.99,2.58-.08.02-.16.03-.24.03ZM11,2.95c-4.84,0-8.78,3.05-8.78,6.79,0,2.33,1.52,4.47,4.06,5.73l.46.23-.14.49c-.35,1.19-.56,1.96-.7,2.46.53-.34,1.43-.95,2.99-2.04l.3-.21.24.03c.52.07,1.07.11,1.58.11,4.84,0,8.78-3.05,8.78-6.79s-3.94-6.79-8.78-6.79Z" style="fill:#fff;" />
    </svg>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.icon-chat{
    width: 22px;
    height: 22px;
}
</style>