var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._b({
    staticClass: "icon-svg",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.type == 'plus' ? 'icon-plus' : '', _vm.type == 'close' ? 'icon-close' : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 22 22"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M12 9.99997V0H10.5V9.99997H0V11.5H10.5V22H12V11.5H22V9.99997H12Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }