<template>
    <div>
        <div v-for="(item, index) in slide" :key="index">
            <v-divider v-if="index !== 0" style="border-color: rgba(255, 255, 255, .2); min-width: 50vw" />
            <v-card tile class="transparent v-card--none-active-bg">
                <div class="px-12px py-20px px-md-30px py-md-50px py-lg-100px pl-lg-80px pr-lg-20px pr-xl-0">
                    <v-row align="center" class="row--lg">
                        <v-col cols="12" sm="auto">
                            <v-img data-aos="zoom-out" data-aos-delay="200" :src="item.image" max-width="80" :aspect-ratio="1 / 1" />
                        </v-col>
                        <v-col>
                            <tit data-aos="fade-up" class="white--text tit--sm line-height-17">{{item.title}}</tit>
                            <txt data-aos="fade-up" data-aos-dealy="100" v-if="item.txt" class="white--text line-height-17 mt-4px mt-md-8px">
                                <span v-html="item.txt"></span>
                            </txt>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";
export default {
    components: {
        Tit,
        Txt,
    },
    data() {
        return {
            slide : [
                { 
                    title : "전문성",
                    txt : "이혼 사건만 12년, 3천 건 이상의 소송 경험, <br class='d-none d-lg-block' />이혼가사전문센터 운영 및 1사건 2인 변호사 <br class='d-none d-lg-block' />(이혼팀 전원 이혼전문등록) 체제",
                    image : "/images/main/expertise-icon.svg",
                },
                { 
                    title : "언론의 신뢰, 다양한 방송출연 및 자문",
                    image : "/images/main/expertise-icon2.svg",
                },
                { 
                    title : "황혼이혼 재산분할 특성화",
                    image : "/images/main/expertise-icon3.svg",
                },
            ]
        }
    }
}
</script>

<style>
</style>