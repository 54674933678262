<template>
    <v-row data-aos="fade-up" class="row--lg">
        <!-- S: 성공사례 -->
        <v-col v-for="board in boards" :key="board._id" cols="6" md="4">
            <v-card :to="`/reference/${board?._id}`" tile class="reference-card transparent v-card--none-active-bg">
                <v-card rounded="sm" class="reference-card__thumb transparent v-card--none-active-bg">
                    <v-img :src="board?.thumb" max-width="440" :aspect-ratio="440 / 622" class="w-100" />
                    <btn-arrow-secondary color="white" class="reference-card__btn" />
                </v-card>
                <tit class="tit--sm text-truncate-2 mt-16px mt-md-32px">{{ board?.subject }}</tit>
            </v-card>
        </v-col>
        <!-- E: 성공사례 -->
    </v-row>
</template>

<script>
import api from "@/api";
import BtnArrowSecondary from "@/components/publish/parents/buttons/btn-arrow-secondary.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";
export default {
    components: {
        BtnArrowSecondary,
        Tit,
        Txt,
    },
    async mounted() {
        await this.init();
    },
    data() {
        return {
            boards: []
        }
    },
    methods: {
        async init() {
            await this.saerch()
        },
        async saerch() {
            let { boards } = await api.v1.boards.gets({})
            this.boards = boards
        }
    }
}
</script>

<style lang="scss" scoped>
.reference-card{
    display: block;
    &__thumb{
        position: relative;
        overflow: hidden;
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.7);
            opacity: 0;
            visibility: hidden;
            z-index: 1;
            transition: 0.2s ease-out;
        }
    }
    &__btn{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(1.4);
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s ease-out;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .reference-card{
        &:hover{
            .reference-card__thumb::after{
                opacity: 1;
                visibility: visible;
                transition: 0.2s ease-out;
            }
            .reference-card__btn{
                opacity: 1;
                visibility: visible;
                transform: translate(-50%, -50%) scale(1);
                transition: 0.2s 0.2s ease-out;
            }
        }
    }
}
@media (min-width: 1200px) {
}
</style>