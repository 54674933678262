var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('main-header'), _c('v-main', {
    attrs: {
      "id": "main"
    }
  }, [_c('main-pc-logo', {
    staticClass: "d-none d-xl-block"
  }), _vm._t("subHead"), _vm._t("contentsImmersive"), _c('div', {
    attrs: {
      "id": "contents"
    }
  }, [_vm._t("default")], 2)], 2), _c('main-footer'), _vm.$vuetify.breakpoint.xlOnly ? _c('btn-top-primary') : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }