<template>
    <client-page class="app--main">
        <main-visual />

        <main-section class="main-section--expertise py-0 mt-md-n2">
            <v-row no-gutters>
                <v-col cols="12" md="6">
                    <div class="main-section--expertise__left">
                        <tit-wrap-primary dark>
                            태성 이혼가사센터
                            <template #TitAdd>Expertise</template>
                        </tit-wrap-primary>
                        <div class="d-flex d-md-block justify-end">
                            <btn-secondary to="/taesung/center" data-aos="zoom-out" data-aos-delay="150" />
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="main-section--expertise__right">
                        <main-expertise />
                    </div>                        
                </v-col>
            </v-row>
        </main-section>

        <main-section class="main-section--media">
            <template #mainSectionImmersive>
                <v-img src="/images/main/media-bg2.svg" aspect-ratio="1840 / 480" class="media-bg media-bg--1 w-100" />
            </template>
            <v-row>
                <v-col cols="12" md="4" class="main-section--media__left">
                    <tit-wrap-primary dark>
                        미디어 속 태성
                        <template #TitAdd>Media</template>
                    </tit-wrap-primary>
                    <div class="d-flex d-md-block justify-end">
                        <btn-secondary to="/media" data-aos="zoom-out" data-aos-delay="150" />
                    </div>
                </v-col>
                <v-col cols="12" md="8">
                    <main-media />
                </v-col>
            </v-row>
        </main-section>

        <main-section class="main-section--introduction">            
            <tit-wrap-primary dark class="text-center">
                변호사 소개
                <template #TitAdd>Introduction</template>
            </tit-wrap-primary>
            <main-introduction />
        </main-section>

        <main-section class="main-section--reference grey lighten-5">    
            <u-tit-wrap-default> 
                <v-row no-gutters>
                    <v-col cols="12" md="">
                        <tit-wrap-primary class="pb-md-0">
                            태성 이혼가사센터 성공사례
                            <template #TitAdd>Reference</template>
                        </tit-wrap-primary>
                    </v-col>
                    <!-- <v-col cols="12" md="auto" class="d-flex d-md-block justify-end">
                        <btn-secondary data-aos="zoom-out" data-aos-delay="150" />
                    </v-col> -->
                </v-row>     
            </u-tit-wrap-default>  
            <main-reference />
        </main-section>

        <main-section class="main-section--about">            
            <tit-wrap-primary dark class="text-center">
                <span class="font-weight-regular secondary--text text--lighten-4">태성 </span>이혼가사센터<span class="font-weight-regular secondary--text text--lighten-4">는 전원 </span>이혼전문변호사<span class="font-weight-regular secondary--text text--lighten-4">들이며</span>
                <br class="d-none d-xl-block"> <span class="font-weight-regular secondary--text text--lighten-4">오직! </span>이혼사건<span class="font-weight-regular secondary--text text--lighten-4"> </span>만 합니다.
                <template #TitAdd>Lawfirm</template>
            </tit-wrap-primary>
            <main-about />
        </main-section>

        <popup-layer></popup-layer>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import MainSection from "@/sets/styles/mains/main-section.vue";
import MainVisual from "@/components/client/main/main-visual.vue";
import MainExpertise from "@/components/client/main/main-expertise.vue";
import MainMedia from "@/components/client/main/main-media.vue";
import MainIntroduction from "@/components/client/main/main-introduction.vue";
import MainReference from "@/components/client/main/main-reference.vue";
import MainAbout from "@/components/client/main/main-about.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";

import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import BtnSecondary from "@/components/publish/parents/buttons/btn-secondary.vue";

export default {
    components: {
        ClientPage,
        MainSection,
        MainVisual,
        MainExpertise,
        MainMedia,
        MainIntroduction,
        MainReference,
        MainAbout,
        PopupLayer,

        TitWrapPrimary,
        UTitWrapDefault,
        BtnPrimary,
        BtnSecondary,
    },
    props: {},
    data() {
        return {};
    },

};
</script>

<style lang="scss" scoped>
.main-section{
    &--expertise{
        overflow: hidden;
        ::v-deep{
            >.container{
                max-width: var(--container-lg);
                padding: 0;
            }
        }
        &__left,
        &__right{
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        &__left{
            background-image: url(/images/main/expertise-bg-mo.jpg);
            padding: var(--page-section-padding-y) var(--container-gutter) 20px;
        }
        &__right{
            background-image: url(/images/main/expertise-bg-mo2.jpg);
        }
    }
    &--media{
        background-image: url(/images/main/media-bg.jpg);
        &__left{
            position: relative;
            z-index: 5;
        }
        .media-bg{
            position: absolute;
            left: 0;
            &--1{
                bottom: 0;
            }
        }
    }
    &--introduction{
        background-image: url(/images/main/introduction-bg.jpg);
    }
    &--about{
        overflow: hidden;
        background-image: url(/images/main/about-bg.jpg);
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .main-section{
        &--expertise{
            ::v-deep{
                >.container{
                    max-width: calc(var(--container-lg) + var(--container-gutter)*2);
                    padding: var(--container-gutter)
                }
            }
            background-image: url(/images/main/expertise-bg.jpg);
            &__left{
                padding: var(--page-section-padding-y) 0;
                background-image: none;
            }
            &__right{
                background-image: none;
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>
