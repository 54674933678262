<template>
    <div v-if="className == 'sub-tab'" :class="className">
        <v-container>
            <template v-for="item in gnbs">
                <tabs-primary v-if="item.title == sh" :key="item.title" hide-slider>
                    <template v-for="child in item.children">
                        <tab-primary :key="child.title" :to="child.path" :class="{ 'v-tab--active': tabActive == child.title }">{{ child.title }}</tab-primary>
                    </template>
                </tabs-primary>
            </template>
        </v-container>
    </div>
    <ul v-else-if="className == 'gnb'" :class="className">
        <template v-for="item in gnbs">
            <li v-if="!item.children" :class="className+'__li'" :key="item.title">
                <router-link :to="item.path" :class="className+'__link'"><h2>{{item.title}}</h2></router-link>
            </li>

            <li v-else :class="className+'__li'" :key="item.title">
                <div :class="className+'__link gnb__link--toggle d-xl-none'"><h2>{{item.title}}</h2></div>
                <router-link :to="item.path" :class="className+'__link gnb__link--toggle d-none d-xl-flex'"><h2>{{item.title}}</h2></router-link>
                <ul :class="className+'__sub'">
                    <li v-for="child in item.children" :key="child.title">
                        <router-link :to="child.path">{{child.title}}</router-link>
                    </li>
                </ul>
            </li>
        </template>
    </ul>
    <ul v-else-if="className == 'slide-gnb'" :class="className">
        <template v-for="(item, index) in gnbs">
            <li :class="className+'__li'" :key="index">
                <router-link :to="item.path" :class="className+'__link'">                    
                    <div :class="className+'__num'">
                        <v-img :src="'/images/common/0'+(index+1)+'.png'" :class="className+'__num-no-active'" height="32" width="46" contain />
                        <v-img :src="'/images/common/0'+(index+1)+'-active.png'" :class="className+'__num-active'" height="32" width="46" contain />
                    </div>
                    <h2>{{item.title}}</h2>
                </router-link>
                <ul v-if="item.children" :class="className+'__sub'">
                    <li v-for="child in item.children" :key="child.title">
                        <router-link :to="child.path" :class="className+'__sub-link'">{{child.title}}</router-link>
                    </li>
                </ul>
            </li>
        </template>
    </ul>
</template>

<script>
import TabsPrimary from "@/components/publish/parents/tabs/tabs-primary.vue";
import TabPrimary from "@/components/publish/parents/tabs/tab-primary.vue";
export default {
    props: {
        className : String,
        indexNum : String,
        sh: String,
        tabActive: String,
    },
    components: {
        TabsPrimary,
        TabPrimary,
    },
    data: function(){
        return {
            window: {
                width: 0,
                height: 0,
            },
            gnbs : [
                {
                    title : "태성 이혼가사센터",
                    path : "/taesung/center",
                    children : [
                        {
                            title : "태성 이혼가사센터 소개",
                            path : "/taesung/center",
                        },
                        {
                            title : "최유나 변호사 소개",
                            path : "/taesung/chio",
                        },
                        {
                            title : "문종하 변호사 소개",
                            path : "/taesung/moon",
                        },
                        {
                            title : "변호사 소개",
                            path : "/taesung/lawyer",
                        },
                    ],
                },
                {
                    title : "이혼",
                    path : "/divorce/agreement",
                    children : [
                        {
                            title : "협의이혼",
                            path : "/divorce/agreement",
                        },
                        {
                            title : "이혼소송",
                            path : "/divorce/lawsuit",
                        },
                        {
                            title : "양육권&친권",
                            path : "/divorce/custody",
                        },
                        {
                            title : "재산분할",
                            path : "/divorce/property",
                        },
                    ],
                },
                {
                    title : "상간자소송",
                    path : "/lawsuit",
                },
                {
                    title : "상속",
                    path : "/inheritance/property",
                    children : [
                        {
                            title : "상속 재산 분할",
                            path : "/inheritance/property",
                        },
                        {
                            title : "유류분",
                            path : "/inheritance/heir",
                        },
                        {
                            title : "상속 회복 청구",
                            path : "/inheritance/claim",
                        },
                    ],
                },
                {
                    title : "미디어 속 태성",
                    path : "/media",
                },
                {
                    title : "오시는 길",
                    path : "/contact/seoul",
                    children : [
                        {
                            title : "서울이혼가사센터",
                            path : "/contact/seoul",
                        },
                        {
                            title : "인천이혼가사센터",
                            path : "/contact/incheon",
                        },
                    ],
                },
            ],
        };
    },
    computed: {
        isDesktop: function() {
            return this.window.width >= 1200;
        },
        isMobile: function() {
            return this.window.width < 1200;
        },
    },
    created: function(){
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted: function(){
    },
    methods: {
        handleResize: function() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    }
};
</script>