var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.slide, function (item, index) {
    return _c('div', {
      key: index
    }, [index !== 0 ? _c('v-divider', {
      staticStyle: {
        "border-color": "rgba(255, 255, 255, .2)",
        "min-width": "50vw"
      }
    }) : _vm._e(), _c('v-card', {
      staticClass: "transparent v-card--none-active-bg",
      attrs: {
        "tile": ""
      }
    }, [_c('div', {
      staticClass: "px-12px py-20px px-md-30px py-md-50px py-lg-100px pl-lg-80px pr-lg-20px pr-xl-0"
    }, [_c('v-row', {
      staticClass: "row--lg",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "sm": "auto"
      }
    }, [_c('v-img', {
      attrs: {
        "data-aos": "zoom-out",
        "data-aos-delay": "200",
        "src": item.image,
        "max-width": "80",
        "aspect-ratio": 1 / 1
      }
    })], 1), _c('v-col', [_c('tit', {
      staticClass: "white--text tit--sm line-height-17",
      attrs: {
        "data-aos": "fade-up"
      }
    }, [_vm._v(_vm._s(item.title))]), item.txt ? _c('txt', {
      staticClass: "white--text line-height-17 mt-4px mt-md-8px",
      attrs: {
        "data-aos": "fade-up",
        "data-aos-dealy": "100"
      }
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(item.txt)
      }
    })]) : _vm._e()], 1)], 1)], 1)])], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }