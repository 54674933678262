import { render, staticRenderFns } from "./u-tit-subtit.vue?vue&type=template&id=774410ac&scoped=true"
import script from "./u-tit-subtit.vue?vue&type=script&lang=js"
export * from "./u-tit-subtit.vue?vue&type=script&lang=js"
import style0 from "./u-tit-subtit.vue?vue&type=style&index=0&id=774410ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "774410ac",
  null
  
)

export default component.exports