import { render, staticRenderFns } from "./tit-wrap.vue?vue&type=template&id=5c10b383&scoped=true"
import script from "./tit-wrap.vue?vue&type=script&lang=js"
export * from "./tit-wrap.vue?vue&type=script&lang=js"
import style0 from "./tit-wrap.vue?vue&type=style&index=0&id=5c10b383&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c10b383",
  null
  
)

export default component.exports