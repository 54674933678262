<template>
    <svg
        v-bind="$attrs"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
        class="icon-svg "
        :class="[color ? color : '', size ? 'icon--size-' + size : '', type == 'plus' ? 'icon-plus' : '', type == 'close' ? 'icon-close' : '',]"
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 9.99997V0H10.5V9.99997H0V11.5H10.5V22H12V11.5H22V9.99997H12Z"/>
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
        type: { type: String, default: "plus" },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-close{
    transform: rotate(45deg);
}
.icon--size {
    &-xx-small {
        height: 16px;
    }
    &-x-small {
        height: 18px;
    }
    &-small {
        height: 20px;
    }
    &-default {
        height: 22px;
    }
    &-large {
        height: 24px;
    }
    &-x-large {
        height: 26px;
    }
    &-xx-large {
        height: 28px;
    }
}
</style>
