export const lawyer = {
    lawyer01: {
        title: "최유나",
        titleEn: "Choi Yuna",
        info: [
            {
                title: "약력",
                infoDetail: [
                    {
                        txt: "법무법인 태성 대표변호사",
                        bold: true,
                    },
                    "대한변호사협회 이혼전문변호사",
                    "대한변호사협회 가사법전문변호사",
                    "상속유류분소송 담당 변호사",
                    "tvN '유퀴즈' 제헌절 특집 출연 이혼전문변호사",
                    "SBS '돌싱포맨', KBS '아침마당' 외 다수방송출연",
                    "전) 매일경제칼럼리스트",
                    "전) 네이버법률판변호사",
                    "'왜이혼하는가' 세바시 200만 뷰 강연자",
                    "베스트셀러 '우리이만헤어져요', '혼자와함께사이' 저자",
                    //
                ],
            },
        ],
        ceo: true,
    },

    lawyer02: {
        title: "문종하",
        titleEn: "Moon Jongha",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "대한변호사협회등록 이혼전문변호사",
                    "네이버 지식인 상담 변호사 (이혼, 가사 전문)",
                    "전)법무법인 서우",
                    "전)법무법인 인성",
                    "전)봄날가정법률사무소",
                    "전)법무법인 혜안",
                    "전)중소기업 법률자문단",
                    //
                ],
            },
        ],
    },

    lawyer03: {
        title: "이준희",
        titleEn: "Lee Junhee",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "대한변호사협회 이혼전문변호사",
                    "제47회 세무사 시험 합격",
                    "경희대학교 국제경영학과 졸업",
                    "서울시립대학교 법학전문대학원 졸업",
                    "전) 대한가정법률복지상담원",
                    "전) 법무법인 선한 변호사",
                    "전)법무법인 청조 실무수습",
                    //
                ],
            },
        ],
    },

    lawyer04: {
        title: "안은경",
        titleEn: "An Eunkyung",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "대한변호사협회 이혼전문변호사",
                    "상속·유류분소송 담당변호사",
                    "대법원 국선변호인",
                    "연세대학교 법학과 졸업",
                    "56회 사법시험 합격",
                    "연수원 46기 ",
                    "서울남부지방법원 민사조정위원",
                    "서울남부지방검찰청 검사직무대리",
                    "주 제네바 UN 한국대표부 인턴 ",
                    "전) 서울남부지방법원 논스톱 국선변호인",
                    "전) 국방부 보통검찰부 피해자 국선변호인",
                    "전) 국가인권위원회 현장인권상담위원",
                    "전) 서울시 마을변호사",
                    "전) 서울가정법원 국선 보조인",
                    "전) 대신증권 사내변호사 ",
                    "전) 법무법인 고도 변호사 ",
                    //
                ],
            },
        ],
    },

    lawyer05: {
        title: "정우중",
        titleEn: "Jung Woojoong",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "대한변호사협회 등록 이혼전문변호사",
                    "광주지방경찰청 인권위원",
                    "전남대학교 법과대학 및 법학전문대학원 졸업",
                    //
                ],
            },
        ],
    },

    lawyer06: {
        title: "임정훈",
        titleEn: "lim Junghoon",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "대한변호사협회 등록 이혼전문변호사",
                    "광주지방검찰청 범죄피해자지원센터 법률지원위원",
                    "광주지방법원 법인파산관재인",
                    "전) 변호사 노로·임정훈 법률사무소",
                    "전) 한국자산관리공사 근무",
                    //
                ],
            },
        ],
    },

    lawyer07: {
        title: "김태은",
        titleEn: "Kim Taeeun",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "대한변호사협회 이혼전문변호사",
                    "전) 법무법인 운율 소속 변호사",
                    "전) 인천광역시 교육청행정심판위원회 국선대리인",
                    "전) 인천 미추홀경찰서 현장 인권 상담위원",
                    "현) 인천남동경찰서 상담변호사",
                    //
                ],
            },
        ],
    },

    lawyer08: {
        title: "윤영환",
        titleEn: "Yoon Younghwan",
        info: [
            {
                title: "약력",
                infoDetail: [
                    {
                        txt: "법무법인 태성 대표변호사",
                        bold: true,
                    },
                    "고려대학교 졸업 (건설 공학 전공)",
                    "건축시공기술사",
                    "토목기사",
                    "대한변호사협회 건설전문변호사",
                    "대한변호사협회 부동산전문변호사",
                    "전) 국회 하자 정책토론위원",
                    "전) 한국건설기술연구원 시민감사관",
                    "전) 건설이코노미뉴스 칼럼니스트 ",
                    "네이버 지식iN 건설 전문가 상담 위원",
                    "공동주택하자소송 400여 건 담당",
                    "MBC 등 언론사 법률자문 제공",
                    "대한주택관리사협회 경기도회 강의",
                    "대한주택관리사협회 광주시회 강의",
                    //
                ],
            },
        ],
        ceo: true,
    },

    lawyer09: {
        title: "박규훈",
        titleEn: "Park Kyuhoon",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "이혼가사사건 담당변호사",
                    "상속·유류분 담당 변호사",
                    "전) 팜팩토리 법률 자문 변호사",
                    "전) 예그리나 법률 자문 변호사",
                    "인하대학교 법학과 졸업",
                    "전북대학교 법학전문대학원 졸업",
                    "사법연수원 하계연수",
                    //
                ],
            },
        ],
    },

    lawyer10: {
        title: "남덕호",
        titleEn: "Nam Deokho",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "이혼 등 가사소송 담당 변호사",
                    "연세대학교 법학전문대학원",
                    "인천지방법원 실무 수습",
                    "정부법무공단 실무 수습",
                    //
                ],
            },
        ],
    },

    lawyer11: {
        title: "구찬회",
        titleEn: "Koo Chanhoe",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "이혼 등 가사소송 담당 변호사",
                    "상속·유류분 담당 변호사",
                    "홍익대학교 법학과 졸업",
                    //
                ],
            },
        ],
    },

    lawyer12: {
        title: "원혜원",
        titleEn: "Won Hyewon",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "이혼가사소송 담당 변호사",
                    "상속·유류분 담당 변호사",
                    "부산대학교 법학전문대학원 졸업",
                    "전) 법무법인 영동 변호사",
                    "전) 법무법인 올흔 자문 변호사",
                    //
                ],
            },
        ],
    },

    lawyer13: {
        title: "정수진",
        titleEn: "Jung Sujin",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "이혼가사소송 담당 변호사",
                    "상속·유류분 담당 변호사",
                    "한양대학교 정책학과 우수 졸업",
                    "한양대학교 법학전문대학원",
                    "헌법재판연구원 실무수습",
                    "수원지방검찰청 안양지청 실무수습",
                    "전) 법무법인 중현",
                    "전) 법무법인(유한) 대륜 본사",
                    //
                ],
            },
        ],
    },

    lawyer14: {
        title: "정지은",
        titleEn: "Chung Jieun",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "이혼가사소송 담당 변호사",
                    "상속·유류분 담당 변호사",
                    "고려대학교 자유전공학부 정치외교학과 졸업",
                    "서울동부지방검찰청 실무수습",
                    //
                ],
            },
        ],
    },
};
