<template>
    <v-row align="start" class="about-card-group row--lg">
        <v-col v-for="(item, index) in about" :key="index" cols="6" class="about-card-col">
            <div :data-aos="item.aos" :data-aos-delay="item.aosDelay" class="about-card-wrap">
                <p v-if="index % 2 !== 0" class="about-card__caption d-none d-md-block font-size-12 vertical-rl line-height-1 letter-spacing-5 grey--text text--lighten-1 font-secondary text-uppercase" >Lawfirm Taesng</p>
                <router-link :to="item.link" class="about-card">
                    <v-card rounded="sm" class="about-card__thumb v-card--none-active-bg transparent">
                        <v-img :src="item.image" max-width="690" :aspect-ratio="690 / 460" class="about-card__thumb__thumb w-100"/>
                        <div class="about-card__active pa-16px">
                            <txt class="primary--text line-height-1 font-secondary font-weight-bold mb-4px mb-md-8px">0{{index+1}}</txt>
                            <tit class="white--text tit--sm mb-16px mb-md-32px">{{item.title}}</tit>
                            <icon-arrow-primary size="x-large" class="white--text"/>
                        </div>
                    </v-card>
                    <div class="about-card__contents pt-16px pt-md-32px d-none d-lg-block"> 
                        <txt class="txt--light line-height-1 font-secondary font-weight-bold mb-4px mb-md-8px">0{{index+1}}</txt>
                        <tit class="white--text tit--sm">{{item.title}}</tit>
                    </div>
                </router-link>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";
export default {
    components: {
        IconArrowPrimary,
        Tit,
        Txt,
    },
    data: () => {
        return {            
            about : [
                {
                    title : "서울이혼가사센터",
                    link : "/contact/seoul",
                    image: "/images/main/about-img.jpg",
                    aos: "fade-up",
                    aosDelay: 100,
                },
                {
                    title : "인천이혼가사센터",
                    link : "/contact/incheon",
                    image: "/images/main/about-img2.jpg",
                    aos: "fade-down",
                    aosDelay: 200,
                },
            ]
        };
    },
}
</script>

<style lang="scss" scoped>
.about-card{
    height: 100%;
    &-wrap{
        height: 100%;
        position: relative;
    }
    &__thumb{
        overflow: hidden;     
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: calc(100% - 12px);
            height:  calc(100% - 12px);
            top: 6px;
            left: 6px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-bottom-right-radius: 14px;
        }
    }
    &__active{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .about-card{
        &-wrap{
            &::before{
                content: "";
                display: block;
                position: absolute;
                width: 1px;
                height: 600px;
                background-color: rgba(255, 255, 255, 0.2);
                right: 0;
            }
        }
        &__caption{
            position: absolute;
            right: 10px;
        }
        &__thumb{
            &::after{
                content: "";
                width: calc(100% - 20px);
                height:  calc(100% - 20px);
                top: 10px;
                left: 10px;
                border-bottom-right-radius: 20px;
            }
        }
    }
    .about-card-group{
        .about-card-col:nth-child(2n){
            .about-card{
                &-wrap{
                    &::before{
                        top: 0;
                        transform: translateY(-100%);
                    }
                }
                &__caption{
                    top: -20px;
                    right: 10px;
                    transform: translateY(-100%);
                }
            }
        }
        .about-card-col:nth-child(2n - 1){
            margin-top: 40px;
            .about-card{
                &-wrap{
                    &::before{
                        bottom: 150px;
                        transform: translateY(100%);
                    }
                }
                &__caption{
                    bottom: -2px;
                    transform: translateY(100%);
                }
            }
        }
    }
}
@media (min-width:1024px){
    .about-card{
        &__contents{
            opacity: 1;
            visibility: visible;
            transition: 0.25s ease-out;
        }
        &__thumb{ 
            &__thumb{
                padding-bottom: 0;
                transition: 0.25s ease-out;
            }        
            &::after{
                border-bottom-right-radius: 36px;
                transform: scaleY(0);
                transition: 0.25s ease-out;
            }
        }
        &__active{
            opacity: 0;
            visibility: hidden;
            transition: 0.25s ease-out;
            >*{
                transform: scale(1.4);
                opacity: 0;
                visibility: hidden;
                transition: 0.25s ease-out;
            }
        }
        &:hover{
            .about-card__contents{
                opacity: 0;
                visibility: hidden;                
            }
            .about-card__thumb::after{
                transform: scaleY(1);
                transition: 0.5s 0.6s ease-out;              
            }
            .about-card__active{
                opacity: 1;
                visibility: visible;   
                >*{
                    transform: scale(1);
                    opacity: 1;
                    visibility: visible;  
                    transition: 0.5s ease-out;
                    &:nth-child(1){
                        transition-delay: 0.25s;
                    }
                    &:nth-child(2){
                        transition-delay: 0.35s;
                    }
                    &:nth-child(3){
                        transition-delay: 0.45s;
                    }
                    &:nth-child(4){
                        transition-delay: 0.55s;
                    }
                }      
            }
        }
    }
    .about-card-group{
        .about-card-col:nth-child(2n - 1){
            margin-top: 80px;
        }
    }
}
@media (min-width:1200px){
}

</style>