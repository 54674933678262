var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "data-aos": "fade-up"
    }
  }, _vm._l(_vm.boards, function (board) {
    return _c('v-col', {
      key: board._id,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('v-card', {
      staticClass: "reference-card transparent v-card--none-active-bg",
      attrs: {
        "to": `/reference/${board === null || board === void 0 ? void 0 : board._id}`,
        "tile": ""
      }
    }, [_c('v-card', {
      staticClass: "reference-card__thumb transparent v-card--none-active-bg",
      attrs: {
        "rounded": "sm"
      }
    }, [_c('v-img', {
      staticClass: "w-100",
      attrs: {
        "src": board === null || board === void 0 ? void 0 : board.thumb,
        "max-width": "440",
        "aspect-ratio": 440 / 622
      }
    }), _c('btn-arrow-secondary', {
      staticClass: "reference-card__btn",
      attrs: {
        "color": "white"
      }
    })], 1), _c('tit', {
      staticClass: "tit--sm text-truncate-2 mt-16px mt-md-32px"
    }, [_vm._v(_vm._s(board === null || board === void 0 ? void 0 : board.subject))])], 1)], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }